import {
  reportTitles,
  recreationTitles,
  promoTitles,
} from './titles';
import {
  reportFilters,
} from './filters';
import { transfersButtons } from './buttons';
import { actions } from '../';
import { getRequestFilters } from './';

const fixedNum = (obj) => {
  const mappedEntries = Object.entries(obj).map(([k, v]) => [k, typeof v === "number" ? +v.toFixed(2) : v]);

  return Object.fromEntries(mappedEntries)
}

const dataTransformator = (data) => data.map(partner => {
  const clientRows = partner.isOpen ? partner.clients.map(client => ({
    ...client.data,
    company_name: partner.data.company_name,
  })) : [];

  clientRows.sort((client1, client2) => client2.bets_count - client1.bets_count);

  return [
    {
      ...partner.data,
      id: partner.id,
      isOpen: partner.isOpen,
    },
    ...clientRows
  ];
}).flat()

const converter = ({ partners, ...data }) => ({
  ...fixedNum(data),
  records: Object.entries(partners).map(([companyName, companyData]) => ({
    id: `partner_${companyName}`,
    isOpen: false,
    data: {
      ...fixedNum(companyData),
      company_name: companyName,
    },
    clients: Object.entries(companyData.clients).map(([projectName, projectData]) => ({
      data: {
        ...fixedNum(projectData),
        project_name: projectName,
      },
    })),
  })),
})

const expandRow = (rowData, data) => {
  return {
    ...data,
    records: data.records.map(t => t.id === rowData.id ? { ...t, isOpen: !t.isOpen } : t)
  };
}

const promoConverter = (result) => {
  return {
    records: Object.keys(result.types_with_specific_fields).flatMap(k => result[k].map(item => ({ ...item, type: k }))),
    promoTypes: Object.keys(result.types_with_specific_fields),
  };
}

const getPromoInfo = () => actions.getByUrl({
  url: '/api/admin/promo/get',
  convertResult: promoConverter,
});

const pages = [
  {
    dataName: 'records',
    textIdent: 'report',
    customTitleTextId: 'recreate',
    link: '/clients_statistic',
    filters: reportFilters,
    titles: reportTitles,
    customFormTitles: recreationTitles,
    isBig: true,
    buttons: transfersButtons,
    isExportable: true,
    transformDataToTableView: dataTransformator,
    showCustomForm: true,
    getInfo: ({ filters, page, sortField }) => actions.getByUrl({
      url: '/api/admin/clients_statistic',
      filters: getRequestFilters(filters, page, sortField),
      convertResult: converter,
    }),
    export: data => actions.exportReport({ data, titles: reportTitles }),
    expandRow: (rowData) => actions.modifyData(data => expandRow(rowData, data)),
    customFormAction: ({ data, callback }) => actions.recreateReport({ data, callback }),

  },
  {
    dataName: 'records',
    textIdent: 'promo',
    link: '/promo',
    titles: promoTitles,
    isBig: true,
    isEditable: true,
    isCreatable: true,
    isRemovable: true,
    getInfo: getPromoInfo,
    create: (data) => actions.createPromo({ data, getInfo: getPromoInfo }),
    edit: ({ data, callback, editableElement }) => actions.editPromo({ data, editableElement, callback, getInfo: getPromoInfo }),
    remove: promo => actions.deletePromo({ promo, getInfo: getPromoInfo }),
  },
]

export {
  pages
}