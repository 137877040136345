import React, { useState, useCallback } from 'react';
// eslint-disable-next-line no-unused-vars
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

import { useSelector } from 'react-redux';
import block from 'bem-cn';

import { getValueFromData, getDataFromValue } from 'features/Admin/data/inputTypes';
import Button from 'components/Button';
import Fields from 'components/Fields';

import './Edit.scss';

const b = block('edit');

const Edit = ({ element, save, close, titles, title }) => {
  const [data, changeData] = useState(Object.entries(titles)
  .reduce((result, t) => t[1].isEditable ? {
    ...result, [t[0]]: {
      type: t[1].type,
      value: getValueFromData(element[t[0]], t[1].type),
      optionsFromRedux: t[1].optionsFromRedux,
      dictionary: t[1].type === 'dictionary' ? t[1].dictionary : null,
    }
  } : result, {}));
  const locale = useSelector(state => state.locale.locale);

  const stop = useCallback(e => {
    e.stopPropagation();
  }, [])

  const changeSpecificData = useCallback((key, value) => {
    changeData({ ...data, [key]: { ...data[key], value } })
  }, [data])

  const fields = Object.entries(data).map((tempField, i) => <Fields fieldName={tempField[0]} fieldData={tempField[1]} onChange={changeSpecificData} key={i} />);

  const submit = useCallback(() => {
    let resultData = {};
    Object.entries(titles).filter(t => t[1].isEditable).map(tempTitle => resultData[tempTitle[0]] = getDataFromValue(data[tempTitle[0]].value, tempTitle[1].type));
    save(resultData)
  }, [titles, save, data]);

  return <div className={b('wrapper')} onClick={close}>
    <article className={b()} onClick={stop}>
      <h2 className={b('title')}>{title}</h2>
      {fields}
      <div className={b('button-container')}>
        <Button callBack={submit} text={locale.save} color="dark-fill" size="container" isSquare fontSize="small" />
      </div>
    </article>
  </div>;
}

export default Edit;