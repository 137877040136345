import React, { useCallback } from 'react';
import block from 'bem-cn';

import './FileInput.scss';

const b = block('file-input');

const FileInput = ({
    value,
    callBack,
    placeholder = '',
    size = 'default',
    isRequired,
    options = [],
    notNeedDefaultValue = false,
    ...props
}) => {
    const stop = useCallback(e => {
        e.preventDefault();
        callBack(undefined);
      }, [callBack])

    return (
        <>
            <label className={b()}>
                <input
                    type='file'
                    placeholder={placeholder || ''}
                    onChange={e => callBack(e.currentTarget.files[0])}
                    className={b('isFile')}
                    required={isRequired}
                    name="file"
                    {...props}
                />
                {typeof value === "string" ? value : value?.name}
                <div className={b('container')}>
                    <div className={b('button')}>+</div>
                    <div className={b('button')} onClick={stop} >-</div>
                </div>
            </label>
        </>
    );
};

export default FileInput;