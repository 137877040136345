import { inputTypes } from 'features/Admin/data/inputTypes';

export const getDefaultValueByType = (type) => {
    switch (type) {
      case inputTypes.multiSelect:
        return [];
      case inputTypes.dictionary: 
        return [];
      case inputTypes.checkBox: 
        return false;
      default:
        return '';
    }
  };