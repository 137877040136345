import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import TableRow from './TableRow';
import TableHeader from './TableHeader';

import './Table.scss';

const b = block('table');

const Table = ({ data, titles, isBig, changedKeys = {}, changeSortField, sortField, editFunc, customFormFunc, removeFunc, expandFunc }) => {
  const platform = useSelector(state => state.auth.platform);
  const [isOpen, setOpen] = useState(false);
  const changeOpen = useCallback(() => setOpen(!isOpen), [isOpen]);

  const needTitles = (!isBig || isOpen || platform === 'desktop') ?
    Object.entries(titles).reduce((newTitles, t) => !t[1].hidden ? { ...newTitles, [t[0]]: t[1] } : { ...newTitles }, {}) :
    Object.entries(titles).reduce((newTitles, t) => t[1].isMain && !t[1].hidden ? { ...newTitles, [t[0]]: t[1] } : { ...newTitles }, {});

  const sortKey = Object.entries(needTitles).find(t => t[1].isSort)?.[0];

  const rows = data.sort((a, b) => a[sortKey] < b[sortKey] ? -1 : 1).map((t, i) => {
    return (
      <TableRow
        key={i}
        data={t}
        titles={needTitles}
        changedKeys={changedKeys}
        editFunc={editFunc}
        customFormFunc={customFormFunc}
        removeFunc={removeFunc}
        expandFunc={expandFunc}
      />
    );
  })

  return <article className={b({ isBig: isBig && isOpen })}>
    {isBig && <div className={b('button')} onClick={changeOpen}>
      {isOpen ? '<' : '>'}
    </div>}
    <TableHeader titles={needTitles} changeSortField={changeSortField} sortField={sortField} />
    {rows}
  </article>;
}

export default Table;