import React, { useState, useCallback, useEffect } from 'react';
import { JsonEditor } from 'jsoneditor-react';
// eslint-disable-next-line no-unused-vars
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

import { useSelector } from 'react-redux';
import block from 'bem-cn';

import { inputTypes, getValueFromData, getDataFromValue } from 'features/Admin/data/inputTypes';
import Input from 'components/Input';
import Button from 'components/Button';
import { Lines } from 'react-preloaders';

import './CustomForm.scss';

const b = block('custom-form');

const CustomForm = ({ element, save, close, titles, title }) => {
  const [data, changeData] = useState(element);
  const locale = useSelector(state => state.locale.locale);
  const state = useSelector(state => state);
  const { isProcess } = useSelector(state => state.admin);

  const stop = useCallback(e => {
    e.stopPropagation();
  }, [])

  const changeSpecificData = useCallback((key, value) => changeData(prevData => ({ ...prevData, [key]: value })), [])

  useEffect(() => {
    Object.entries(titles).map(([title, titleMeta]) => {
      if (!titleMeta.value) {
        return;
      }

      changeSpecificData(title, titleMeta.value);
    });
  }, [titles]);

  const fields = Object.entries(titles).map(tempTitle => {
    const value = getValueFromData(data[tempTitle[0]], tempTitle[1].type)
    return <div key={tempTitle[0]} className={b('item')}>
      <div className={b('name')}>
        {locale[tempTitle[0]]}
      </div>
      {tempTitle[1].type === inputTypes.json ?
      <JsonEditor
        value={data[tempTitle[0]]}
        search={false}
        navigationBar={false}
        statusBar={false}
        mode="code"
        onChange={value => changeSpecificData(tempTitle[0], value)}
      /> : 
      <Input
        id={tempTitle[0]}
        type={tempTitle[1].type}
        value={value || ''}
        options={tempTitle[1].options || (tempTitle[1].optionsFromRedux ? tempTitle[1].optionsFromRedux(state, { fields: data }) : {})}
        callBack={value => changeSpecificData(tempTitle[0], value)}
      />}
    </div>
  })

  const submit = useCallback(() => {
    let resultData = {};
    Object.entries(titles).map(tempTitle => resultData[tempTitle[0]] = getDataFromValue(data[tempTitle[0]], tempTitle[1].type));
    save(resultData)
  }, [titles, save, data]);

  return <div className={b('wrapper')} onClick={close}>
    <article className={b()} onClick={stop}>
      <h2 className={b('title')}>{title}</h2>
      {fields}
      <div className={b('button-container')}>
        <Button callBack={submit} text={locale.ok} color="dark-fill" size="container" isSquare  fontSize="small" />
      </div>
      {isProcess && <Lines color="#FF8D00" background="rgba(19, 23, 29, 0.3)" customLoading={isProcess} />}
    </article>
  </div>;
}

export default CustomForm;