export const languages = {
  fr: 'fr',
  en: 'en',
  ru: 'ru',
  it: 'it',
  fa: 'fa',
  ar: 'ar',
  pt: 'pt',
  zh: 'zh',
  sq: 'sq',
};
