const betsStatuses = {
  1: 'won',
  2: 'lost',
}

const transfersTypes = {
  1: 'bet',
  2: 'won',
  3: 'return',
  5: 'jackpotWin',
}

const clientsSelector = state => state.auth.clients.reduce((obj, t) => ({ ...obj, [t.id]: `${t.id}_${t.company_name}` }), {} )

const projectNameSelector = state => state.auth.clients.reduce((obj, t) => ({...obj, [t.project_name]: t.project_name}), {})

const headCompanyNameSelector = state => state.auth.clients.reduce((obj, t) => ({...obj, [t.head_company_name]: t.head_company_name}), {})

const companyNameSelector = (state, params) => state.auth.clients.reduce((obj, t) => {
  if (!params?.headCompanyName) {
    return {...obj, [t.company_name]: t.company_name};
  }

  if (t.head_company_name === params?.headCompanyName) {
    return {...obj, [t.company_name]: t.company_name};
  }

  return obj;
}, {});

const promoTypeSelector = state => state.admin.data?.promoTypes?.reduce((obj, t) => ({...obj, [t]: t}), {}) ?? {};

export { betsStatuses, transfersTypes, clientsSelector, projectNameSelector, headCompanyNameSelector, companyNameSelector, promoTypeSelector }