import { addNotify } from 'features/Notify';
import xlsx from "json-as-xlsx"

const actionTypes = {
  GET_DATA: 'GET_DATA',
  GET_CONVERSION: 'GET_CONVERSION',
  SET_IS_PROCESS: 'admin/SET_IS_PROCESS',
  GET_GAMES: 'admin/GET_GAMES',
  CLEAR_DATA: 'admin/CLEAR_DATA',
  LOADING_SUCCESS: 'admin/LOADING_SUCCESS',
  LOADING_START: 'admin/LOADING_START',
}

const getByUrl = ({ url, filters, convertResult = f => f }) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const { role, userId, onlyClientID } = getState().auth;
  const client_id = role === 1 ? +filters?.client_id || onlyClientID : userId;

  const response = await api.admin.getByUrl({
    url,
    filters: filters && { ...filters, client_id, }
  });

  if (response.success) {
    dispatch({ type: actionTypes.GET_DATA, payload: response.data && convertResult(response.data) })
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });

}

const getTransfers = filters => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const { role, userId, onlyClientID } = getState().auth;
  const client_id = role === 1 ? +filters.client_id || onlyClientID : userId;
  const response = await api.admin.getTransfers({ ...filters, client_id, type: filters.type ? +filters.type : undefined });

  if (response.success) {
    dispatch({ type: actionTypes.GET_DATA, payload: response.data })
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const setToken = data => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const response = await api.admin.setToken(data.data);

  if (response.success) {
    dispatch(getByUrl({ url: '/api/admin/clients' }));
    dispatch(addNotify(locale.successSetToken, 'success'))
    if (data.callback) {
      data.callback();
    }
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const exportReport = ({ data, titles, transformDataToTableView = f => f }) => (_, getState) => {
  const { locale } = getState().locale;
  const dataKeys = Object.keys(titles);

  const columns = dataKeys.map(dataKey => ({
    label: locale[dataKey] || dataKey,
    value: dataKey,
  }));

  columns.sort((a, b) => titles[a.value].priority - titles[b.value].priority);

  const report = [
    {
      sheet: 'Report',
      columns,
      content: data.records.map(partner => [
        partner.data,
        ...partner.clients.map(client => client.data)
      ]).flat(),
    },
  ];

  const settings = {
    fileName: "Report",
    extraLength: 3,
  };

  xlsx(report, settings);
}

const modifyData = (modificationFunc = f => f) => (dispatch, getState) => {
  const { data } = getState().admin;
  const updatedData = modificationFunc(data);
  dispatch({ type: actionTypes.GET_DATA, payload: updatedData })
}

const recreateReport = ({ data, callback }) => async (dispatch, getState, extra) => {
  const { api } = extra;
  const { locale } = getState().locale;

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const response = await api.admin.recreateReport(
    {
      from_date: data.from_date,
      to_date: data.to_date,
      company_name: data.project_name,
      head_company_name: data.company_name,
    }
  );

  if (response.success) {
    dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
    dispatch(addNotify(locale.successRecreation, 'success'));
    if (callback) {
      callback();
    }
  } else {
    console.log(response.codeStatus)
    dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
    if(response.codeStatus === 6) {
      dispatch(addNotify(locale.notFullDay, 'error'))
    } else {
      dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
    }
  }
}

const createPromo = ({ data, getInfo }) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;

  const responseImgPromises = Object.entries(data.fields.images).map(async ([key, value]) => {
    const normalizedName = `${data.fields.name}_${value.lastModified}_${value?.name}`.replace(/\s+/g, '');
    await api.admin.uploadImage({ file: value, name: normalizedName });
    return [key, normalizedName];
  });

  const responseImg = await Promise.all(responseImgPromises);

  const response = await api.admin.createPromo({
    ...data.fields,
    clients: data.fields.clients.map(item => +item),
    images: Object.fromEntries(responseImg),
  });

  if (response.success) {
    dispatch(getInfo());
    dispatch(addNotify(locale.successCreated, 'success'))
    if (data.callback) {
      data.callback();
    }
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const editPromo = ({ data, callback, editableElement, getInfo }) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;

  const responseImgPromises = Object.entries(data.images).map(async ([key, value]) => {
    if (typeof value === 'string') {
      return [key, value];
    }
    const normalizedName = `${data.name}_${value.lastModified}_${value?.name}`.replace(/\s+/g, '');
    await api.admin.uploadImage({ file: value, name: normalizedName });
    return [key, normalizedName];
  });

  const responseImg = await Promise.all(responseImgPromises);

  const response = await api.admin.editPromo({
    ...data,
    id: editableElement.id,
    clients: data.clients.map(item => +item),
    images: Object.fromEntries(responseImg),
  });

  if (response.success) {
    dispatch(getInfo());
    dispatch(addNotify(locale.successEdit, 'success'))
    if (callback) {
      callback();
    }
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const deletePromo = ({ promo, getInfo }) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const response = await api.admin.deletePromo({
    id: promo.id,
    type: promo.type,
  });
  if (response.success) {
    dispatch(getInfo());
    dispatch(addNotify(locale.successRemoved, 'success'))
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

export {
  actionTypes,
  getByUrl,
  setToken,
  getTransfers,
  exportReport,
  modifyData,
  recreateReport,
  createPromo,
  editPromo,
  deletePromo,
}
