import React, { useMemo } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';

import Select from 'react-select';
import FileInput from 'components/FileInput';
import CheckBox from 'components/CheckBox';

import './Input.scss';
import { useCallback } from 'react';

// unique value for select all option
const selectAllValue = '@@__multiselect_select_all__@@';

const Input = (
  {
    value,
    name,
    callBack,
    placeholder = '',
    size = 'default',
    type = 'text',
    isRequired,
    options = [],
    notNeedDefaultValue = false,
    ...props
  }) => {
  const { locale } = useSelector(state => state.locale);
  const b = block('input');

  const fullOptionList = useMemo(() => {
    let allOptions = Object.entries(options).map(t => ({ value: t[0], label: locale[t[1]] ? locale[t[1]] : t[1] }));

    if (type === 'multiSelect') {
      allOptions = [{ value: selectAllValue, label: 'All' }, ...allOptions];
    }

    if (!notNeedDefaultValue) {
      allOptions = [{ value: null, label: locale.notSelected }, ...allOptions];
    }

    return allOptions;
  }, [notNeedDefaultValue, options, locale, type]);

  const handleSelectAll = useCallback(() => {
    const allValues = Object.keys(options)
    const selectedValues = allValues === value?.length ? [] : allValues;
    callBack(selectedValues);
  }, [value?.length, options, callBack]);

  // Checking for an input type

  switch (type) {
    case 'select': {
      return (
        <Select
          onChange={opt => callBack(opt.value)}
          value={fullOptionList.find(x => x.value === value)}
          styles={customStyles}
          options={fullOptionList}
          isMulti={false}
        />
      );
    }
    case 'multiSelect': {
      return (
        <Select
          onChange={opt => opt?.some(x => x.value === selectAllValue)
            ? handleSelectAll()
            : callBack(opt?.map(x => x.value).flat() ?? [])}
          value={fullOptionList.filter(x => value?.some(v => v.toString() === x.value))}
          styles={customStyles}
          options={fullOptionList}
          isMulti={true}
        />
      );
    }
    case 'file': {
      return (
        <FileInput
          value={value}
          callBack={callBack}
          {...props}
        />
      )
    }
    case 'checkBox': {
      return (
        <CheckBox
        value={value}
        changeValue={callBack}
        {...props}
        />
      )
    }
    default: return (
      <input
        type={type.replace('UTC_', '')}
        name={name}
        value={value}
        placeholder={placeholder || ''}
        onChange={e => callBack(e.currentTarget.value)}
        className={b({ sizable: size, type })}
        required={isRequired}
        {...props}
      />
    )
  }
};

//Styles for selector
const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    color: '#FFFFFFF',
    background: '#2A3040',
  }),

  control: (provided, state) => {
    const baseStyles = {
      ...provided,
      color: '#FF8D00',
      background: 'transparent',
      borderColor: '#FFFFFF',
      borderRadius: '0',
      maxHeight: '35px',
      minHeight: '35px',
    }

    return state.isMulti ? {
      ...baseStyles,
      overflowY: 'hidden',
      overflowX: 'hidden',
    } : baseStyles;
  },

  singleValue: (provided, state) => ({
    ...provided,
    color: '#FFFFFF',
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    background: 'transparent',
  }),

  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? '#FF8D00' : '#2A3040',
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: state.isMulti ? '35px': provided.height,
    overflow: state.isMulti ? 'scroll': provided.overflow,
  })
}

export default Input;