import dayjs from 'dayjs';
import { languages } from 'utils/languages';
import { inputTypes } from './inputTypes';
import { companyNameSelector, headCompanyNameSelector, clientsSelector, promoTypeSelector } from './selectors';

const reportTitles = {
  project_name: {
    priority: 1,
    type: inputTypes.string,
    isMain: true,
    isForSort: true,
  },
  company_name: {
    priority: 0,
    type: inputTypes.string,
    isMain: true,
    isForSort: true,
  },
  admin_link: {
    priority: 2,
    type: inputTypes.link,
    isMain: false,
    isBig: true,
  },
  bets_count: {
    priority: 7,
    type: inputTypes.number,
    isMain: false,
    isBig: true,
  },
  winnings_count: {
    priority: 6,
    type: inputTypes.number,
    isMain: false,
    isBig: true,
  },
  total: {
    priority: 4,
    type: inputTypes.number,
    isMain: true,
  },
  total_bet: {
    priority: 2,
    type: inputTypes.number,
    isMain: true,
  },
  total_win: {
    priority: 3,
    type: inputTypes.number,
    isMain: true,
  },
  royalty_percent: {
    priority: 8,
    type: inputTypes.number,
    isMain: false,
    isBig: true,
  },
  royalty_amount: {
    priority: 5,
    type: inputTypes.number,
    isMain: true,
  },
}

const recreationTitles = {
  from_date: {
    value: dayjs().add(-1, 'day').format('YYYY-MM-DD'),
    type: inputTypes.date,
    priority: 0,
  },
  to_date: {
    value: dayjs().format('YYYY-MM-DD'),
    type: inputTypes.date,
    priority: 1,
  },
  company_name: {
    type: inputTypes.select,
    optionsFromRedux: headCompanyNameSelector,
    priority: 2,
  },
  project_name: {
    type: inputTypes.select,
    optionsFromRedux: (state, params) => companyNameSelector(state, {
      headCompanyName: params?.fields?.company_name,
    }),
    priority: 3,
  },

}

const promoTitles = {
  id: {
    priority: 0,
    type: inputTypes.string,
  },
  clients: {
    priority: 1,
    type: inputTypes.multiSelect,
    optionsFromRedux: clientsSelector,
    isEditable: true,
    isCreatable: true,
  },
  images: {
    priority: 2,
    type: inputTypes.dictionary,
    dictionary: {
      key: {
        type: inputTypes.select,
        options: languages,
        label: 'Language',
      },
      value: {
        type: inputTypes.file,
        label: 'File name',
      },
    },
    isEditable: true,
    isCreatable: true,
  },
  texts: {
    priority: 3,
    type: inputTypes.dictionary,
    dictionary: {
      key: {
        type: inputTypes.select,
        options: languages,
        label: 'Language',
      },
      value: {
        type: inputTypes.string,
        label: 'Text',
      },
    },
    isEditable: true,
    isCreatable: true,
  },
  type: {
    priority: 4,
    type: inputTypes.select,
    optionsFromRedux: promoTypeSelector,
    isEditable: true,
    isCreatable: true,
  },
  is_active: {
    priority: 5,
    type: inputTypes.checkBox,
    isEditable: true,
    isCreatable: true,
  },
  name: {
    priority: 6,
    type: inputTypes.string,
    isEditable: true,
    isCreatable: true,
  },
  created_at: {
    value: dayjs().add(-1, 'day').format('YYYY-MM-DD'),
    type: inputTypes.UTC_date,
    priority: 7,
  },

}

export {
  reportTitles,
  recreationTitles,
  promoTitles,
}