import dayjs from 'dayjs';
import { inputTypes } from './inputTypes';

// Fill in the fields for sending the filter in the request
const getRequestFilters = (filters, field = {}) => {
  const requestFilters = {};
  Object.entries(filters).forEach(t => {
    if (t[1].value === null || t[1].value === '') requestFilters[t[0]] = undefined // If field is empty
    else if (t[1].type === inputTypes.date && t[0] === 'to_date') requestFilters[t[0]] = dayjs(t[1].value).add(1, 'day').format('DDMMYYYY ZZ') // If field type is date and it is last day, we converting it to need format and add 1 day
    else if (t[1].type === inputTypes.date) requestFilters[t[0]] = dayjs(t[1].value).format('DDMMYYYY ZZ') // If field type is date, we converting it to need format
    else if (t[1].type === inputTypes.UTC_date) requestFilters[t[0]] = dayjs(t[1].value).format('DDMMYYYY') // If field type is UTC date, we converting it to need format without timezone
    else if (t[1].type === inputTypes.number) requestFilters[t[0]] = +t[1].value // If field type is number, we reduce to the number
    else if (t[1].type === inputTypes.select && t[0] === 'status') requestFilters[t[0]] = +t[1].value // If field type is date and it is last day, we converting it to need format and add 1 day
    else requestFilters[t[0]] = t[1].value; // In other cases, we just use value
  });

  requestFilters.order_by = field?.field ? { order_field: field.field, sorting_type: field.type } : undefined; // Sort field

  return requestFilters;
}

export { getRequestFilters } ;