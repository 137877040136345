import React, { useCallback } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import Input from 'components/Input';

import './ComplexInput.scss';
import trash from 'components/Table/TableRow/img/trash.svg';

const b = block('complex-input');

const ComplexInput = ({firstFieldType, secondFieldType, firstOptions, secondOptions, firstValueLabel, secondValueLabel, callBack, value}) => {
    const onAddButtonClick = useCallback(() => {
        callBack([...value, ['', '']]);
    }, [callBack, value]);

    const onDeleteItemButtonClick = useCallback((index) => {
        callBack(value.filter((_, i) => i !== index));
    }, [callBack, value]);

    const handleKeyChange = useCallback((itemIndex, valueIndex, updatedValue) => {
        const modifiedData = value.map((currentItem, currentItemIndex) => {
            if (currentItemIndex !== itemIndex) {
                return currentItem;
            }

            return currentItem.map((currentValue, currentValueIndex) => currentValueIndex === valueIndex ? updatedValue : currentValue);
        });
        
        callBack(modifiedData);
    }, [callBack, value]);

    return (
        <div className={b({withItems: value?.length !== 0})}>
            {value?.length !== 0 ? <div className={b('grid')}>
                <div>{firstValueLabel}</div>
                <div>{secondValueLabel}</div>
            </div>
                : null}

            {value?.map((item, index) => (
                <div className={b('grid')} key={index}>
                    <Input value={item[0]} type={firstFieldType} options={firstOptions} callBack={value => handleKeyChange(index, 0, value)} />
                    <Input value={item[1]} type={secondFieldType} options={secondOptions} callBack={value => handleKeyChange(index, 1, value)} />
                    <SVGInline className={b('edit').toString()} svg={trash} alt='X' onClick={() => onDeleteItemButtonClick(index)} />
                </div>
            ))}
            <div className={b('add-button')} onClick={onAddButtonClick} >+</div>
        </div>
    );
};

export default ComplexInput;