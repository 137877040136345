import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import './TableHeader.scss';

const TableHeader = ({ titles, changeSortField, sortField = {} }) => {
  const b = block('table-header');
  const { locale } = useSelector(state => state.locale);

  const items = Object.entries(titles).sort((a, b) => a[1].priority - b[1].priority).map((t, i, a) => {
    const isSortField = t[0] === sortField.field;
    const isSortable = t[1].isForSort;
    const func = f => isSortable ? changeSortField(t[0]) : f;
    const typeText = isSortField ? sortField.type === 'asc' ? '↑' : '↓' : '';

    return <div
      key={i}
      className={b('item', { isSortable })}
      onClick={() => func()}
      style={{ width: `calc(100% / ${a.length})` }}>
      {`${locale[t[0]] || t[0]} ${typeText}`}
    </div>

  })
  return <section className={b()}>
    {items}
  </section>

}

export default TableHeader;