import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import Input from 'components/Input';
import Button from 'components/Button';

import './Filters.scss';

const Filters = ({ filters, buttons = {}, setFilter, callback, setPage, page, pagesAmount = 0 }) => {
  const b = block('filters');
  const { locale } = useSelector(state => state.locale);
  const { isProcess } = useSelector(state => state.admin);
  const auth = useSelector(state => state.auth);
  const state = useSelector(state => state);

  const accessedFilters = useMemo(() => 
    Object.entries(filters).filter(t => !t[1].accessRoles || t[1].accessRoles.findIndex(t1 => t1 === auth.role) !== -1)
    .reduce((obj, t) => ({ ...obj, [t[0]] : t[1]}), {}), [auth.role, filters])

  const chengeOneFilter = useCallback((name, value) => setFilter(state =>
    ({
      ...state,
      [name]: {
        ...state[name],
        value
      }
    })), [setFilter]);

  const filtersList = useMemo(() => Object.entries(accessedFilters).sort((a, b) => a[1].priority = b[1].priority).map(filter =>
    <label className={b('item')} key={filter[0]}>
      <span className={b('label')}>{locale[filter[1].textIdent] || filter[0]}</span>
      <Input
        id={`filter_${filter[0]}`}
        type={filter[1].type}
        value={filter[1].value || ''}
        options={filter[1].options || (filter[1].optionsFromRedux ? filter[1].optionsFromRedux(state, { filters }) : {})}
        callBack={value => chengeOneFilter(filter[0], value)}
      />
    </label>
  ), [state, b, chengeOneFilter, accessedFilters, locale])

  const onSubmit = useCallback(e => {
    e.preventDefault();
    if (page === 0) {
      callback()
    } else {
      setPage(0);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [callback, setPage])

  const pageOptions = useMemo(() => {
    const obj = {};
    for (let i = 0; i < pagesAmount + 1; i++) {
      obj[i] = i;
    } 
    return obj;
  }, [pagesAmount])

  const buttonsList = Object.values(buttons).map(t => <div className={b('item', { isButton: true })} key={t.textIdent}>
    <Button callBack={t.func} text={locale[t.textIdent]} color="dark-fill" size="container" isSquare isDisabled={isProcess} fontSize="small" />
  </div>)

  return <form
  className={b()}
  onSubmit={onSubmit}>
    {buttonsList}
    {filtersList}

    <div className={b('item', { isSubmitButton: true })} key="submit_button">
      <Button type="submit" text={locale.get} color="dark" size="container" isSquare isDisabled={isProcess} />
    </div>

    {pagesAmount !== 0 && <div className={b('item')} key="page_counter">
      <span className={b('label')}>{locale.page}</span>
      <Input
        id={`filter_page`}
        type="select"
        value={page}
        options={pageOptions}
        notNeedDefaultValue
        callBack={value => setPage(+value)}
      />
    </div>}
  </form>;
}

export default Filters;