import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import Button from 'components/Button';
import Fields from 'components/Fields';
import { getDataFromValue } from 'features/Admin/data/inputTypes';
import { getDefaultValueByType } from './data/index';

import './Create.scss';

const b = block('create');

const getData = (titles) => Object.entries(titles)
.reduce((result, t) => t[1].isCreatable ? {
  ...result, [t[0]]: {
    type: t[1].type,
    value: getDefaultValueByType(t[1].type),
    optionsFromRedux: t[1]?.optionsFromRedux,
    dictionary: t[1].type === 'dictionary' ? t[1].dictionary : null,
  }
} : result, {})

const Create = ({ titles = {}, title = '', callback = f => f }) => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);

  const [isOpen, setOpen] = useState(false);
  const [data, changeData] = useState(getData(titles));

  const stop = useCallback(e => {
    e.stopPropagation();
  }, [])

  const changeSpecificData = useCallback((key, value) => {
    changeData({ ...data, [key]: { ...data[key], value } })
  }, [data])

  const fields = Object.entries(data).map((tempField, i) => <Fields fieldName={tempField[0]} fieldData={tempField[1]} onChange={changeSpecificData} key={i} />)

  const onSubmit = useCallback(() => {
    dispatch(callback({
      fields: Object.entries(data).reduce((result, t) => ({ ...result, [t[0]]: getDataFromValue(t[1].value, t[1].type) }), {}),
      callback: () => {
        setOpen(false);
        changeData(getData(titles));
      },
    }))
  }, [callback, data, dispatch, titles])

  return isOpen ?
    <div className={b('wrapper')} onClick={() => setOpen(false)}>
      <article className={b()} onClick={stop}>
        <h2 className={b('title')}>{`${locale.create} ${title}`}</h2>
        {fields}
        <div className={b('button-container')}>
          <Button callBack={onSubmit} text={locale.create} color="dark-fill" size="container" isSquare fontSize="small" />
        </div>
      </article>
    </div>
    : <div className={b('add-button')} onClick={() => setOpen(true)}>
      +
    </div>
}

export default Create;