import React, { useCallback } from 'react';
import { JsonEditor } from 'jsoneditor-react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import ComplexInput from 'components/ComplexInput';
import Input from 'components/Input';
import { inputTypes } from 'features/Admin/data/inputTypes';

import './Fields.scss';

const b = block('fields');

const fieldRendererMap = {
  [inputTypes.json]: ({ fieldData, onChange }) => (
    <JsonEditor
      value={fieldData.value}
      search={false}
      navigationBar={false}
      statusBar={false}
      mode="code"
      onChange={onChange}
    />
  ),
  [inputTypes.dictionary]: ({ state, fieldData, onChange }) => (
    <ComplexInput
      type={fieldData.type}
      firstFieldType={fieldData.dictionary.key.type}
      secondFieldType={fieldData.dictionary.value.type}
      firstOptions={
        fieldData.dictionary.key.options ||
        (
          fieldData.dictionary.key.optionsFromRedux &&
          fieldData.dictionary.key.optionsFromRedux(state)
        )
      }
      secondOptions={
        fieldData.dictionary.value.options ||
        (
          fieldData.dictionary.value.optionsFromRedux &&
          fieldData.dictionary.value.optionsFromRedux(state)
        )
      }
      firstValueLabel={fieldData.dictionary.key.label}
      secondValueLabel={fieldData.dictionary.value.label}
      callBack={onChange}
      value={fieldData.value}
    />
  ),
}

const defaultFieldRenderer = ({ state, fieldName, fieldData, onChange }) => (
  <Input
    id={fieldName}
    type={fieldData.type}
    value={fieldData.value}
    callBack={onChange}
    options={fieldData.optionsFromRedux && fieldData.optionsFromRedux(state)}
  />
);

const Fields = ({ fieldName, fieldData, onChange }) => {
  const state = useSelector(state => state);
  const locale = useSelector(state => state.locale.locale);

  const handleChange = useCallback(value => onChange(fieldName, value), [onChange, fieldName]);
  const fieldRenderer = fieldRendererMap[fieldData.type] || defaultFieldRenderer;

  return (
    <div key={fieldName} className={b()}>
      <div className={b('name')}>
        {locale[fieldName]}
      </div>
      {fieldRenderer({ fieldName, fieldData, state, onChange: handleChange })}
    </div>
  );

};

export default Fields;