import dayjs from 'dayjs';

import { projectNameSelector, headCompanyNameSelector, companyNameSelector, clientsSelector, transfersTypes } from  './selectors';
import { inputTypes } from './inputTypes';


//Filters must be specified in the same format { value: any, type: on of inputTypes, priority: number, textIdent: 'string', options: object (not require) }

const reportFilters = {
  from_date: {
    value: dayjs().format('YYYY-MM-DD'),
    type: inputTypes.UTC_date,
    priority: 1,
    textIdent: 'from',
  },
  to_date: {
    value: dayjs().format('YYYY-MM-DD'),
    type: inputTypes.UTC_date,
    priority: 2,
    textIdent: 'to',
  },
  project_name: {
    value: null,
    type: inputTypes.select,
    optionsFromRedux: projectNameSelector,
    priority: 3,
    textIdent: 'project',
  },
  head_company_name: {
    value: null,
    type: inputTypes.select,
    optionsFromRedux: headCompanyNameSelector,
    priority: 4,
    textIdent: 'headCompany',
  },
  company_name: {
    value: null,
    type: inputTypes.select,
    optionsFromRedux: (state, params) => companyNameSelector(state, {
      headCompanyName: params?.filters?.head_company_name?.value,
    }),
    priority: 5,
    textIdent: 'company_name',
  },

}

export {
  reportFilters,
};