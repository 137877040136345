import dayjs from 'dayjs';

export const inputTypes = {
  number: 'number',
  text: 'text',
  date: 'date',
  select: 'select',
  bool: 'bool',
  json: 'json',
  array: 'array',
  link: 'link',
  UTC_date: 'UTC_date',
  multiSelect: 'multiSelect',
  dictionary: 'dictionary',
  file: 'file',
  checkBox: 'checkBox',
}

export const getDataFromValue = (value, type) => {
  switch (type) {
    case inputTypes.date: return dayjs(value).format('DDMMYYYY');
    case inputTypes.UTC_date: return dayjs(value).format('DDMMYYYY ZZ');
    case inputTypes.array: return value.toUpperCase().replaceAll(' ', '').split(',');
    case inputTypes.bool: return value === '+' ? true : false;
    case inputTypes.dictionary: return Object.fromEntries(value);
    default: return value;
  }
};

export const getValueFromData = (data, type) => {
  switch (type) {
    case inputTypes.number: return data;
    case inputTypes.text: return data;
    case inputTypes.date: return dayjs(data).format('YYYY-MM-DD');
    case inputTypes.UTC_date: return dayjs(data).format('YYYY-MM-DD');
    case inputTypes.json: return JSON.stringify(data, null, 2);
    case inputTypes.bool: return data ? '+' : '-';
    case inputTypes.array: return data;
    case inputTypes.dictionary: return Object.entries(data);
    default: return data;
  }
}