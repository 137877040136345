import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import Create from 'components/Create';
import Table from 'components/Table';
import Graphic from 'components/Graphic';
import GlobalButtons from '../GlobalButtons';
import Filters from '../Filters';
import Edit from '../Edit';
import CustomForm from '../CustomForm';
import AdditionalInfo from '../AdditionalInfo';
import Button from 'components/Button';

import './Page.scss';

const Page = ({ info }) => {
  const b = block('page');
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.admin);
  const { locale } = useSelector(state => state.locale);

  // Sort
  const [sortField, setSortField] = useState({
    field: null,
    type: null,
  })

  const changeSortField = useCallback(fieldName => {
    if (sortField.field === fieldName) {
      if (sortField.type === 'asc') {
        setSortField({ ...sortField, type: 'desc'})
      } else  setSortField({ field: null, type: null, })      
    } else {
      setSortField({ field: fieldName, type: 'asc'})
    }
  }, [sortField])

  // Filter
  const [filters, setFilter] = useState(info.filters || {});

  // Buttons functions
  const buttonsFunctions = useMemo(() => ({
    setIntervalDates: (fromDate, toDate) => {
      setFilter(state =>
        ({
          ...state,
          from_date: {
            ...state.from_date,
            value: fromDate
          },
          to_date: {
            ...state.to_date,
            value: toDate
          },
        })
      )
    },
  }), []);

  // Buttons
  const buttons = useMemo(() => Object.entries(info.buttons || {}).reduce((o, t) => (
    {
      ...o,
      [t[0]]: { ...t[1], func: () => t[1].func(buttonsFunctions) } 
    }), {}), [buttonsFunctions, info])

  const [page, setPage] = useState(0);

  const getInfo = useCallback(() => dispatch(info.getInfo({ filters, page, sortField })),
  [dispatch, filters, info, page, sortField])

  useEffect(() => {
    getInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortField]);

  // Additionals
  const additionals = useMemo(() => info.specificAdditionals ? info.specificAdditionals(data) : {
    ...data,
    records: undefined,
    clients: undefined
  }, [data, info])

  // Edit 
  const [editableElement, setEditableElement] = useState(null);

  const saveChanges = useCallback(data =>
    dispatch(info.edit({ data, editableElement, callback: () => setEditableElement(null) })),
    [dispatch, editableElement, info]);

  const editBlock = useMemo(() => editableElement ?
    <Edit
      element={editableElement}
      titles={info.titles}
      save={saveChanges}
      title={editableElement[info.editableElement || locale.edit]}
      close={() => setEditableElement(null)} /> :
    null, [editableElement, info, locale.edit, saveChanges])
    
  // Custom
  const [customFormElement, setCustomFormElement] = useState(null);

  const customFormAction = useCallback(data =>
    dispatch(info.customFormAction({ data, customFormElement })),
  [dispatch, customFormElement, info]);

  const customBlock = useMemo(() => customFormElement ?
  <CustomForm
    element={customFormElement}
    titles={info.customFormTitles}
    save={customFormAction}
    title={locale[info.customTitleTextId]}
    close={() => setCustomFormElement(null)} /> :
  null, [customFormElement, info, locale, customFormAction])
  
  const transformedData = useMemo(() => {
    const rawData = data[info.dataName] || [];
    const converter = info.transformDataToTableView || (f => f);
    return converter(rawData);
  }, [data, info]);

  return <article className={b()}>
    <div className={b('title')}>{locale[info.textIdent]}</div>
    {info.globalButtons && <GlobalButtons buttons={info.globalButtons} />}
    {!info.withoutFilters && <Filters
      filters={filters}
      buttons={buttons}
      setFilter={setFilter}
      callback={getInfo}
      setPage={setPage}
      pagesAmount={Math.trunc(data.count / 50) || 0}
      page={page} />}
    {!info.withoutAdditional && <AdditionalInfo info={additionals} />}
    {info.titles && <Table
      data={transformedData}
      titles={info.titles}
      changeSortField={changeSortField}
      sortField={sortField}
      isBig={info.isBig}
      changedKeys={info.changedKeys}
      editFunc={info.isEditable ? setEditableElement : undefined}
      customFormFunc={info.showCustomForm ? setCustomFormElement : undefined}
      expandFunc={info.expandRow}
      removeFunc={info.remove} />}
    {info.graphics && <Graphic data={data} conversionFialds={info.graphics} />}
    {info.isCreatable && <Create titles={info.titles} callback={info.create} title={locale[info.textIdent]}/>}
    {info.isExportable && (
      <div className={b('export')}>
        <Button callBack={() => dispatch(info.export(data))} color='orange' text={locale.export} />
      </div>
    )}
    {info.isEditable && editBlock}
    {info.showCustomForm && customBlock}
  </article>;
}

export default Page;